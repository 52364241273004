.educationalProcess {
    display: grid;
    justify-items: start;

    .content {
        padding: 0 100px;

        @media screen and (max-width: 860px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }
    }

    h3 {
        color: #0b4596;
    }

    h4 {
        font-size: 24px;

        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
    }
}