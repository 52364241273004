@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 1920px;
  margin: 0 auto;
  font-family: "Ubuntu Condensed", sans-serif;
}

/* .container {
  display: grid;
  gap: 30px;
  padding: 60px 30px;

  @media screen and (max-width: 500px) {
    padding: 30px 20px;
  }
}

.container h3 {
  font-size: 36px;
  color: #0b4596;

  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
}

.container hr {
  width: 100%;
  height: 1px;
  background: #0b4596;
} */
