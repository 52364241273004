.footer {
    background-color: #262727;

    .footer-container {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: center;
            gap: 20px;
            padding: 20px;
        }

        a {
            color: #fff;
            font-size: 18px;
            text-decoration: none;
            transition: 0.3s;

            @media screen and (max-width: 860px) {
                font-size: 16px;
            }

            @media screen and (max-width: 700px) {
                text-align: center;
            }

            &:hover {
                color: #3083c9;
            }
        }

        .footer-contacts {
            display: grid;
            gap: 10px;
        }

        .footer-icons {
            display: flex;
            gap: 20px;

            svg {
                color: #fff;
                font-size: 20px;
                transition: 0.3s;

                &:hover {
                    color: #3083c9;
                }
            }
        }
    }
}