.vacancy {
    display: grid;
    gap: 30px;
    padding: 60px 30px;

    @media screen and (max-width: 500px) {
        padding: 30px 20px;
    }

    h3 {
        font-size: 36px;
        color: #0b4596;
    }

    hr {
        width: 100%;
        height: 1px;
        background: #0b4596;
    }

    .content {
        display: grid;
        gap: 20px;
        padding: 0 100px;

        @media screen and (max-width: 860px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }

        b {
            color: #0b4596;
            font-size: 24px;
            opacity: 0.8;
            display: flex;
            align-items: center;
            gap: 30px;
            margin-bottom: 20px;
            cursor: pointer;

            svg {
                font-size: 20px;
            }
        }

        .accordion {
            display: grid;
            gap: 20px;

            div {
                display: grid;
                gap: 5px;

                h4 {
                    font-size: 20px;
                }

                ul {
                    display: grid;
                    gap: 2px;

                    li {
                       font-size: 18px; 
                    }
                }
            }

            span {
                font-size: 18px;

                a {
                    display: block;
                    padding-top: 5px;
                    text-decoration: none;
                    font-size: 16px;
                }
            }
        }
    }
}