section {
    display: grid;
    justify-items: center;
    gap: 100px;

    @media screen and (max-width: 860px) {
        gap: 50px;
    }

    .slider-img {
        img {
            width: 100%;
        }
    }

    h3 {
        font-family: "Fredoka", sans-serif;
        font-size: 40px;
        color: #3afb44;
        line-height: 40px;

        @media screen and (max-width: 1440px) {
            font-size: 34px;
        }

        @media screen and (max-width: 1085px) {
            font-size: 28px;
        }

        @media screen and (max-width: 500px) {
            line-height: 30px;
        }
    }

    hr {
        width: 130px;
        height: 3px;
        background: #3afb44;
        border: none;
    }

    p {
        font-size: 20px;
        line-height: 24px;
        color: #333;

        @media screen and (max-width: 1440px) {
            font-size: 18px;
        }

        @media screen and (max-width: 1085px) {
            font-size: 16px;
        }

        @media screen and (max-width: 500px) {
            line-height: 22px;
        }
    }

    .news-block {
        display: grid;
        gap: 30px;
        padding: 0 100px;

        @media screen and (max-width: 860px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
        }

        h3 {
            text-align: end;

            @media screen and (max-width: 500px) {
                text-align: start;
            }
        }

        .news-title {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;

            a {
                text-align: end;
                font-size: 16px;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }
        }

        .new-items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;

            @media screen and (max-width: 1024px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 860px) {
                grid-template-columns: 1fr;
            }
        }
    }

    .about {
        padding: 0 100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center;
        gap: 30px;

        @media screen and (max-width: 860px) {
            grid-template-columns: 1fr;
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            h3 {
                padding-bottom: 20px;
            }

            p {
                line-height: 28px;
                letter-spacing: 0.5px;

                @media screen and (max-width: 500px) {
                    line-height: 22px;
                }
            }
        }

        .img {
            display: grid;
            justify-content: center;
            max-width: 600px;

            @media screen and (max-width: 860px) {
                max-width: none;
            }

            img {
                width: 100%;
                border-radius: 50%;
            }
        }
    }

    .why {
        .why-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            h3 {
                color: #333;
            }

            p {
                line-height: 28px;
                letter-spacing: 0.5px;

                @media screen and (max-width: 500px) {
                    line-height: 22px;
                }
            }
        }
    }

    .home-block {
        padding: 0 100px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;

        @media screen and (max-width: 860px) {
            grid-template-columns: 1fr;
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
        }

        .items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 500px;
            padding: 20px;
            transition: 0.7s;

            &:hover {
                transform: scale(1.05);
            }

            .text {
                display: grid;
                gap: 20px;

                p {
                    font-family: "Fredoka", sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                }
            }

            .img {
                max-width: 200px;

                img {
                    width: 100%;
                    transition: transform 500ms linear;

                    &:hover {
                        transform: scale(1.3);
                    }
                }
            }
        }
    }

    .model {
        padding: 0 100px;
        display: grid;
        gap: 30px;
        text-align: center;

        @media screen and (max-width: 860px) {
            gap: 20px;
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
        }

        p {
            padding-bottom: 10px;
        }

        .model-img {
            max-width: 1920px;

            img {
                width: 100%;
            }
        }
    }

    .development {
        padding: 0 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 60px;
        text-align: center;

        @media screen and (max-width: 860px) {
            gap: 20px;
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
        }

        .items-block {
            max-width: 320px;
        }

        .items {
            display: grid;
            justify-items: center;
            gap: 20px;
            width: 100%;

            @media screen and (max-width: 860px) {
                gap: 10px;
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 134px;
                height: 134px;
                border-radius: 50%;
            }
        }
    }

    .research {
        background: rgb(245, 245, 245);

        .research-container {
            padding: 60px 100px;

            @media screen and (max-width: 860px) {
                padding: 30px 30px;
            }

            @media screen and (max-width: 500px) {
                padding: 30px 20px;
            }

            .text {
                p {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .foreign-language {
        padding: 0 100px;
        display: grid;
        gap: 30px;

        @media screen and (max-width: 860px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0 20px;
        }

        h3 {
            text-align: center;
        }

        .language-cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            @media screen and (max-width: 860px) {
                grid-template-columns: 1fr;
            }
        }

        .language-card {
            display: grid;
            gap: 20px;
            align-items: center;

            .language-img {
                max-width: 550px;

                @media screen and (max-width: 860px) {
                    max-width: none;
                }

                img {
                    width: 100%;
                }
            }
        }

        span {
            text-align: center;
            font-size: 30px;
            color: #777777;
            font-weight: 600;
            margin-bottom: 60px;

            @media screen and (max-width: 1440px) {
                font-size: 24px;
            }

            @media screen and (max-width: 1085px) {
                font-size: 20px;
            }
        }
    }
}
