.home-slider {
    
    .slider  {

    }

    div {
        width: 1920px;
        height: 800px;
        
        img {
            width: 1920px;
        }
    }
}