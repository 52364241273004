.volunteers {
    display: grid;

    .content {

        div {
            max-width: 700px;

            img {
                width: 100%;
            }
        }
    }   
}