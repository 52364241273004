header {
    padding: 30px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
    }

    .logo {
        max-width: 60px;

        a {
            img {
                width: 100%;
            }
        }
    }

    button {
        border: 2px solid #777777;
        border-radius: 5px;
        outline: none;
        background-color: transparent;
        color: #777777;
        font-size: 17px;
        font-weight: 600;
        cursor: pointer;
        transition: 0.3s;
        padding: 3px 5px;

        @media screen and (max-width: 1440px) {
            font-size: 15px;
        }

        @media screen and (max-width: 1085px) {
            color: #000;
            border: 2px solid #000;
        }

        &:hover {
            color: #4ccdf4;
            border: 2px solid #4ccdf4;
        }
    }

    nav {
        display: flex;
        align-items: center;
        gap: 20px;
        @media screen and (max-width: 1085px) {
            display: none;
        }
        ul {
            display: flex;
            align-items: center;
            gap: 30px;

            @media screen and (max-width: 1440px) {
                gap: 20px;
            }

            li {
                list-style: none;

                a {
                    text-decoration: none;
                    color: #777777;
                    font-size: 17px;
                    font-weight: 700;
                    transition: 0.3s;
                    padding-bottom: 5px;
                    display: grid;

                    @media screen and (max-width: 1440px) {
                        font-size: 15px;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: #4ccdf4;
                        border-bottom: 2px solid #4ccdf4;
                    }
                }
            }
        }
    }

    .menu {
        display: none;
        cursor: pointer;

        @media screen and (max-width: 1085px) {
            display: block;
            display: flex;
            align-items: center;
            gap: 20px;

            .modal {
                background-color: rgba(0, 0, 0, 0.5);
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .menu-modal {
                position: fixed;
                z-index: 1;
                top: 0;
                right: 0;
                background-color: #4ccdf4;
                height: 100%;
                width: 60%;
                padding: 20px;
                animation: anim 0.7s linear forwards;

                @media screen and (max-width: 500px) {
                    width: 80%;
                }

                .menu-close {
                    display: flex;
                    justify-content: end;
                    color: #fff;
                    padding-bottom: 30px;
                    cursor: pointer;
                }

                ul {
                    display: grid;
                    gap: 20px;

                    li {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;

                        svg {
                            color: #fff;
                            font-size: 20px;

                            &:hover,
                            &:active,
                            &:focus {
                                color: #3afb44;
                            }
                        }
                    }

                    a,
                    li {
                        text-decoration: none;
                        color: #fff;
                        font-size: 20px;

                        @media screen and (max-width: 500px) {
                            font-size: 18px;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            color: #3afb44;
                        }
                    }

                    div {
                        display: grid;
                        gap: 10px;

                        a {
                            font-size: 18px;
                            color: #fff;
                            text-decoration: none;

                            @media screen and (max-width: 500px) {
                                font-size: 16px;
                            }

                            &:hover,
                            &:active,
                            &:focus {
                                color: #3afb44;
                            }
                        }
                    }
                }
            }
        }

        svg {
            font-size: 30px;
        }
    }
}

@keyframes anim {
    0% {
        width: 0;
    }
    100% {
        opacity: 1;
    }
}
