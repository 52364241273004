.aboutUs {
    background-color: #4ccdf4;
    padding: 10px;
    position: absolute;
    z-index: 1;
    width: 300px;

    nav {
        display: grid;
        gap: 10px;

        a {
            text-decoration: none;
            color: #fff;
            font-size: 18px;
            transition: 0.3s;

            &:hover,
            &:active,
            &:focus {
                color: #777;
            }
        }
    }
}
