.contactsPage {
    padding: 60px 30px;
    display: grid;
    gap: 20px;

    @media screen and (max-width: 500px) {
        padding: 30px 20px;
    }

    h3 {
        font-size: 28px;

        @media screen and (max-width: 500px) {
            font-size: 24px;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
        }

        .contacts {
            background-image: url("../../img/contactsBackground.png");
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            padding: 30px;
            border-radius: 10px 0 0 10px;

            @media screen and (max-width: 800px) {
                border-radius: 10px 10px 0 0;
            }

            b {
                font-size: 24px;
                color: #fff;
                text-transform: uppercase;

                @media screen and (max-width: 500px) {
                    font-size: 20px;
                }
            }

            hr {
                width: 100px;
                border: 1px solid #fff;
                margin-top: 10px;
            }

            span {
                font-size: 20px;
                color: #fff;
                font-weight: 600;

                @media screen and (max-width: 500px) {
                    font-size: 18px;
                }

                a {
                    text-decoration: none;
                    color: #fff;
                    font-weight: 400;
                    padding-left: 5px;

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }

                    &:hover {
                        color: #3083c9;
                    }
                }
            }
        }

        .forma {
            display: grid;
            gap: 20px;

            b {
                font-size: 24px;
                text-transform: uppercase;

                @media screen and (max-width: 500px) {
                    font-size: 20px;
                }
            }

            hr {
                width: 100px;
                border: 1px solid #0b4596;
                margin-top: 10px;
            }

            form {
                display: grid;
                gap: 15px;

                input, textarea {
                    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
                    border-radius: 5px;
                    outline: none;
                    border: 2px solid #eeee;
                    font-size: 14px;
                    padding: 10px;
                }

                span {
                    font-size: 14px;
                    color: #777777;
                }

                button {
                    background-color: #0b4596;
                    color: #fff;
                    border-radius: 5px;
                    padding: 10px;
                    border: none;
                    cursor: pointer;
                    font-size: 18px;
                    transition: 0.7s;

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }

                    &:hover {
                        background-color: #3083c9;
                    }
                }
            }
        }
    }
}
