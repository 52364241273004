.accreditation {
    display: grid;

    h3 {
        color: #0b4596;
    }

    .content {
        display: grid;
        justify-items: center;
        
        b {
            font-size: 24px;
        }

        .form {
            display: grid;
            gap: 10px;

            input {
                outline: none;
                border: none;
                border-radius: 5px;
                padding: 10px;
                font-size: 18px;
                box-shadow: 1px 2px 13px 0px rgba(0, 0, 0, 0.12);
            }

            button {
                background-color: #0b4596;
                color: #fff;
                border-radius: 5px;
                padding: 10px;
                border: none;
                cursor: pointer;
                font-size: 18px;
                transition: 0.7s;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}