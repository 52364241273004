.galleryPage {
    padding: 60px 30px;

    @media screen and (max-width: 500px) {
        padding: 30px 20px;
    }

    div {

        img {
            max-width: 600px;
            width: 100%;
            border-radius: 10px;
            transition: transform 0.3s linear;

            &:hover {
                transform: scale(1.03);
            }
        }

        p {
            font-size: 18px;
            margin-top: 5px;

            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
        }
    }
}