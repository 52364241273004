.cooperation {
    display: grid;
    justify-items: start;

    .content {
        padding: 0 100px;

        @media screen and (max-width: 860px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }

        .text {
            display: grid;
            gap: 10px;
            b {
                font-size: 24px;
            }
    
            p {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}