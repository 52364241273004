.news {
    display: grid;

    .news-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        padding: 0 100px;

        @media screen and (max-width: 1085px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }
    }
}
