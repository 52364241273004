.news-items {
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 30, 50, 0.25);
    transition: transform 500ms ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.03);
    }

    a {
        text-decoration: none;
        color: #000;

        img {
            width: 100%;
            height: 300px;
            border-radius: 10px 10px 0 0;
        }

        div {
            display: grid;
            gap: 10px;
            padding: 20px;

            p {
                line-height: 28px;
                font-size: 20px;

                @media screen and (max-width:500px) {
                    font-size: 18px;
                }
            }

            hr {
                width: 100%;
                height: 1px;
                background-color: #bebbbb;
                border: none;
            }

            span {
                font-size: 20px;
                opacity: 50%;
                text-align: end;

                @media screen and (max-width:500px) {
                    font-size: 18px;
                }
            }
        }
    }
} 