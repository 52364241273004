.about-page {
    padding: 30px;
    display: grid;
    gap: 30px;

    @media screen and (max-width: 500px) {
        padding: 20px;
    }

    h3 {
        text-transform: uppercase;
        font-size: 30px;
        color: #0b4596;

        @media screen and (max-width: 500px) {
            font-size: 24px;
        }
    }

    .text {
        display: grid;
        gap: 15px;
        padding: 0 100px;

        @media screen and (max-width: 800px) {
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }

        h4 {
            font-size: 22px;

            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
        }

        p {
            font-size: 20px;
            line-height: 24px;
            color: #333;

            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
        }
    }

    .about-mission {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 0 100px;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            padding: 0 30px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
        }

        .about-img {
            max-width: 600px;

            @media screen and (max-width: 800px) {
                max-width: none;
            }

            img {
                width: 100%;
                border-radius: 10px;
                border: 10px solid #fff;
                box-shadow: 0 0 5px rgba(0, 0, 0, 1);
            }
        }

        .mission-text {
            display: grid;
            gap: 30px;

            div {
                display: grid;
                gap: 15px;

                b {
                    font-size: 22px;

                    @media screen and (max-width: 500px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 20px;
                    line-height: 24px;
                    color: #333;

                    @media screen and (max-width: 500px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
